<template>
  <div class="pt-4">
    <div
      v-for="(story, index) in impactStories.slice(0, length)"
      :key="index"
      class="mb-4"
    >
      <div class="">
        <div class="">
          <img  :src="story.image" alt="" />
        </div>
        <div class="mt-4">
          <p class="text-sm font-semibold">{{ story.title }}</p>
        </div>
      </div>
      <p class="text-sm font-medium mt-3">{{ story.description }}</p>
      <hr
        class="my-4 border-t border-border-primary"
        v-if="index + 1 < impactStories.length"
      />
    </div>

    <button
      v-if="impactStories.length > 3"
      class="
        h-12
        w-full
        rounded
        bg-bg-alternate-3
        text-sm
        font-bold
        text-text-alternate-10
        mt-4
      "
      @click="setLength"
    >
      {{ length == 3 ? 'Show more' : 'Show less' }}
    </button>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from 'vue';
const length = ref(3);
const props = defineProps({
  fundraiser: {
    type: Object,
  },
});
const impactStories = computed(() => {
  return props.fundraiser.impactstories;
});

const setLength = () => {
  if (length.value == 3) {
    length.value = impactStories.value.length;
  } else {
    length.value = 3;
  }
};
</script>

<style></style>
